import React from 'react';

import {
    HeroBanner,
    CaseHeader,
    Testimonial,
    TestimonialType,
    TextContent,
    Gallery,
    GalleryLayout,
    Team,
} from 'modules/references';

import socialPreview from '../../../static/social-previews/dpnk.png';
import Seo from '../../components/SEO';
import { ReferenceCategory } from '../../constants';
import { sendCaseStudyPageView } from '../../utils/analytics';

const CASE_ID = 'dpnk';
const MODULE_NAME = 'do-prace-na-kole';
const IMAGE_PATH = `references/case-studies/${MODULE_NAME}`;

const colors = {
    primary: '#B0D9C7',
    primaryLighter: '#D6495A',
    primaryDakrer: '#1C1343',
    secondary: '#3F2F88',
    grey: '#E5E6E8',
    textPrimary: '#000',
    green: '#0CCE6B',
    red: '#EF2D56',
};

const team = [
    { department: 'references.case.team.management', names: ['Martin Vavroušek'] },
    {
        department: 'references.case.team.design',
        names: ['Filip Hudák'],
    },
    {
        department: 'references.case.team.ux',
        names: ['Markéta Hejná'],
    },
    {
        department: 'references.case.team.android',
        names: ['Ondřej John', 'Peter Konečný'],
    },
    {
        department: 'references.case.team.ios',
        names: ['Vendula Švastalová', 'Lukáš Hromadník'],
    },
    {
        department: 'references.case.team.testing',
        names: ['Martin Votýpka'],
    },
];

const DPNK = () => (
    <>
        <Seo image={socialPreview} pageId={CASE_ID} handleMetadata={sendCaseStudyPageView} />
        <CaseHeader />
        <HeroBanner
            content={{
                projectName: `case.${CASE_ID}.hero.project`,
                projectTitle: `case.${CASE_ID}.hero.title`,
                projectYear: `case.${CASE_ID}.hero.year`,
                client: `case.${CASE_ID}.hero.client`,
                image: {
                    src: `${IMAGE_PATH}/hero-image.png`,
                    style: { height: '60%', width: '100%', marginLeft: '-40%', marginTop: 40, top: -50 },
                },
            }}
            background={colors.primary}
            backgroundImage={{
                src: `${IMAGE_PATH}/hero-bg.png`,
                imgStyle: {
                    top: 'unset',
                    left: 'unset',
                    bottom: 0,
                    width: '100%',
                    height: '64%',
                    objectFit: 'contain',
                    objectPosition: 'bottom right',
                },
            }}
            textColor={colors.textPrimary}
        />
        <TextContent
            content={{
                section: `case.${CASE_ID}.section1.name`,
                title: `case.${CASE_ID}.section1.title`,
                text: `case.${CASE_ID}.section1.text`,
                category: ReferenceCategory.TRAVEL,
                outputs: [
                    'references.case.output.ux',
                    'references.case.output.design',
                    'references.case.output.android',
                    'references.case.output.ios',
                    'references.case.output.testing',
                ],
            }}
        />
        <Gallery
            layout={GalleryLayout.TWO_THIRDS}
            content={[
                {
                    background: colors.secondary,
                    image: {
                        src: `${IMAGE_PATH}/gallery_1.png`,
                        style: {
                            width: '90%',
                            height: 'unset',
                            maxHeight: '100%',
                            display: 'block',
                            margin: 'auto',
                        },
                    },
                },
                {
                    background: colors.primaryDakrer,
                    image: {
                        src: `${IMAGE_PATH}/gallery_2.png`,
                        style: { width: '100%', height: '100%', display: 'block', margin: 'auto' },
                        imgStyle: { objectFit: 'cover' },
                    },
                },
            ]}
        />
        <TextContent
            content={{
                section: `case.${CASE_ID}.section2.name`,
                title: `case.${CASE_ID}.section2.title`,
                text: `case.${CASE_ID}.section2.text`,
            }}
        />
        <Gallery
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: colors.secondary,
                    image: {
                        src: `${IMAGE_PATH}/gallery_3.png`,
                        style: { width: '90%', height: '100%', display: 'block', margin: 'auto' },
                    },
                },
            ]}
        />
        <TextContent
            content={{
                section: `case.${CASE_ID}.section3.name`,
                title: `case.${CASE_ID}.section3.title`,
                text: `case.${CASE_ID}.section3.text`,
            }}
        />
        <Gallery
            layout={GalleryLayout.ONE_THIRD}
            content={[
                {
                    background: colors.primaryLighter,
                    image: {
                        src: `${IMAGE_PATH}/gallery_4.png`,
                        style: { width: '100%', height: '100%', display: 'block', margin: 'auto' },
                        imgStyle: { objectFit: 'cover' },
                    },
                },
                {
                    background: colors.primary,
                    image: {
                        src: `${IMAGE_PATH}/gallery_5.png`,
                        style: { width: '90%', height: '90%', display: 'block', margin: 'auto' },
                    },
                },
            ]}
        />
        <TextContent
            content={{
                section: `case.${CASE_ID}.section4.name`,
                title: `case.${CASE_ID}.section4.title`,
                text: `case.${CASE_ID}.section4.text`,
            }}
        />
        <Gallery
            layout={GalleryLayout.EQUAL}
            content={[
                {
                    background: colors.primary,
                    image: {
                        src: `${IMAGE_PATH}/gallery_6.png`,
                        style: { width: '90%', height: '90%', display: 'block', margin: 'auto', marginBottom: 0 },
                    },
                },
            ]}
        />
        <Testimonial
            headerTheme="dark"
            background={colors.grey}
            content={{
                quote: `case.${CASE_ID}.testimonial.text`,
                person: {
                    name: `case.${CASE_ID}.testimonial.name`,
                    role: `case.${CASE_ID}.testimonial.position`,
                    image: {
                        src: `${IMAGE_PATH}/testimonial.jpg`,
                    },
                },
            }}
            type={TestimonialType.PROFILE_BOTTOM}
        />
        <Team content={team} />
    </>
);

export default DPNK;
